import React from 'react'
import { Button } from 'rebass'
import { ALERT_ADD } from 'constants/actionType'
import { readExcel } from 'utilities/excelUtil'
import { request } from 'utilities/requestUtil'
import TextInput from 'components/TextInput'
import Table from 'components/Table'
import Icon from 'components/Icon'
import { MdDelete } from 'react-icons/md'

export const initialState = (value = {}) => ({
  trackingNoItems: [],
})

export const fields = ({ state, setState, message }) => {
  return {
    trackingNo: (
      <Table
        columns={[
          {
            id: 'ticketId',
            label: 'trackingNo.field.ticketId',
            render: ({ row, index }) => (
              <TextInput
                containerProps={{ m: 0 }}
                value={row.ticketId}
                onChange={(event) => {
                  const trackingNoItems = [...state.trackingNoItems]
                  const trackingNoItem = trackingNoItems[index]
                  trackingNoItem.ticketId = event.target.value
                  trackingNoItems.splice(index, 1, trackingNoItem)
                  setState({ ...state, trackingNoItems })
                }}
              />
            ),
          },
          {
            id: 'trackingNo',
            label: 'trackingNo.field.trackingNo',
            render: ({ row, index }) => (
              <TextInput
                containerProps={{ m: 0 }}
                value={row.trackingNo}
                onChange={(event) => {
                  const trackingNoItems = [...state.trackingNoItems]
                  const trackingNoItem = trackingNoItems[index]
                  trackingNoItem.trackingNo = event.target.value
                  trackingNoItems.splice(index, 1, trackingNoItem)
                  setState({ ...state, trackingNoItems })
                }}
              />
            ),
          },
          {
            id: 'actions',
            align: 'right',
            render: ({ row, index }) => (
              <Button
                type="button"
                variant="table"
                onClick={() => {
                  const trackingNoItems = [...state.trackingNoItems]
                  trackingNoItems.splice(index, 1)
                  setState({ ...state, trackingNoItems })
                }}
              >
                <Icon>
                  <MdDelete />
                </Icon>
              </Button>
            ),
          },
        ]}
        rows={state.trackingNoItems}
      />
    ),
  }
}

export const handlers = ({
  app,
  session,
  state,
  setState,
  setDisabled,
  message,
}) => ({
  loadExcel: async (file) => {
    const ticketIdKey = message({ id: 'trackingNo.field.ticketId' })
    const trackingNoKey = message({ id: 'trackingNo.field.trackingNo' })
    const data = await readExcel(file)
    const newItems = data.map((item) => ({
      ticketId: item[ticketIdKey],
      trackingNo: item[trackingNoKey],
    }))
    const trackingNoObj = [...state.trackingNoItems, ...newItems].reduce(
      (result, item) => {
        result[item.ticketId] = item.trackingNo
        return result
      },
      {}
    )
    const trackingNoItems = Object.entries(trackingNoObj).reduce(
      (result, [ticketId, trackingNo]) => {
        result.push({ ticketId, trackingNo })
        return result
      },
      []
    )
    setState({ ...state, trackingNoItems })
    setDisabled(false)
  },
  addTrackingNo: () => {
    const trackingNoItems = [
      ...state.trackingNoItems,
      { ticketId: '', trackingNo: '' },
    ]
    setState({ ...state, trackingNoItems })
    setDisabled(false)
  },
  handleSubmit: async ({ setResult }) => {
    const trackingNoItems = state.trackingNoItems.filter(
      ({ ticketId }) => !!ticketId
    )
    if (trackingNoItems.length === 0) return

    const input = trackingNoItems.map((item) => ({
      ticketId: item.ticketId,
      status: 'SHIPPED',
      extra: {
        memo: item.trackingNo,
        status: 'SHIPPED',
      },
    }))
    const variables = { ticketType: 'SELL', input }
    const query = `
      mutation($ticketType: String!, $input: [TicketStatusInput]!) {
        editTicketStatuses(ticketType: $ticketType, input: $input)
      }
    `
    const [ok, data] = await request({ query, variables }, { app, session })
    if (!ok) return null

    const result = data.editTicketStatuses
    const success = result.filter(({ ok }) => ok)
    if (success.length > 0) {
      const successSet = new Set(success.map(({ ticketId }) => ticketId))
      const errorItems = [...trackingNoItems].filter(
        ({ ticketId }) => !successSet.has(ticketId)
      )
      setState({ ...state, trackingNoItems: errorItems })
    }

    const errors = result.filter(({ ok }) => !ok)
    if (errors.length === 0) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'success', message: 'trackingNo.message.success' },
      })
      return
    }
    setResult(errors)
  },
})
